import React, { useState } from 'react'
import { baseUrl } from '../util/http'
import axios from 'axios'
import Logo from '../images/buzz_logo.png'

export default function ResetPass() {
  let [password, setPassword] = useState('')
  let [repeatPassword, setRepeat] = useState('')
  let [email, setEmail] = useState('')
  let [message, setMessage] = useState({ color: '#000', message: '' })

  let updatePass = async (e) => {
    e.preventDefault()
    try {
      if (password !== repeatPassword) {
        setMessage({ color: 'red', message: 'Password Mismatch' })
      } else {
        let body = {
          email,
          password,
        }
        await axios.post(`${baseUrl}/users/updatePassword`, body)
        setMessage({ color: 'green', message: 'updated successfully!' })
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <nav
        style={{
          height: 80,
          backgroundColor: 'purple',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: `0px 30px`,
        }}
      >
        <img
          src={Logo}
          style={{ maxWidth: '50%', maxHeight: '50%' }}
          alt="logo"
        />
      </nav>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-4 mt-5">
            <h3>Reset Password</h3>
            <hr />
            <form onSubmit={updatePass}>
              <div className="mb-2">
                <label
                  htmlFor=""
                  style={{
                    fontFamily: `'Quicksand', sans-serif`,
                    marginBottom: 4,
                    fontSize: 14,
                    color: '#000',
                  }}
                >
                  Admin Email
                </label>
                <input
                  type="Email"
                  placeholder="Enter Email Address"
                  className="form-control form-control-lg form_input"
                  required
                  onChange={({ target: { value } }) => setEmail(value)}
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor=""
                  style={{
                    fontFamily: `'Quicksand', sans-serif`,
                    marginBottom: 4,
                    fontSize: 14,
                    color: '#000',
                  }}
                >
                  Password
                </label>
                <input
                  type="password"
                  placeholder="Enter Password"
                  className="form-control form-control-lg form_input"
                  required
                  onChange={({ target: { value } }) => setPassword(value)}
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor=""
                  style={{
                    fontFamily: `'Quicksand', sans-serif`,
                    marginBottom: 4,
                    fontSize: 14,
                    color: '#000',
                  }}
                >
                  Repeat Password
                </label>
                <input
                  type="password"
                  placeholder="Repeat Password"
                  className="form-control form-control-lg form_input"
                  required
                  onChange={({ target: { value } }) => setRepeat(value)}
                />
              </div>
              <p style={{ marginBottom: 20, color: message.color }}>
                {message.message}
              </p>
              <button className="btn btn-secondary">Reset</button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
