import './App.css'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Landing from './screens/Landing'
import Dashboard from './screens/Dashboard'
import ResetPass from './screens/ResetPass'

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Landing />,
    },
    {
      path: 'dashboard',
      element: <Dashboard />,
    },
    {
      path: 'reset-password',
      element: <ResetPass />,
    },
  ])

  return <RouterProvider router={router} />
}

export default App
