import React, { useState, useEffect } from 'react'
import Logo from '../images/buzz_logo.png'
import axios from 'axios'
import { baseUrl } from '../util/http'

export default function Dashboard() {
  let [users, setUsers] = useState([])
  let [resetText, setResetText] = useState('')

  useEffect(() => {
    checkIfLoggedIn()
    fetchUsers()
  }, [])

  let checkIfLoggedIn = () => {
    let user = window.localStorage.getItem('buzzUser')
    if (!user) {
      window.location = '/'
    }
  }

  let fetchUsers = async () => {
    let userName = window.localStorage.getItem('buzzUser')
    let _fetch = await axios.post(`${baseUrl}/users/everybody`, {
      me: userName,
    })
    if (_fetch.data.message === 'successful') {
      setUsers(_fetch.data.everybody)
      console.log(_fetch.data.everybody)
    }
  }

  let resetApp = async () => {
    setResetText('Resetting App, Please Wait!')
    let _fetch = await axios.get(`${baseUrl}/users/reset`)
    setResetText('Reset Complete!')
  }

  let takeAction = async (userName, status) => {
    try {
      let _fetch = await axios.post(`${baseUrl}/users/activate`, {
        userName,
        status,
        me: window.localStorage.getItem('buzzUser'),
      })
      if (_fetch.data.message === 'successful') {
        setUsers(_fetch.data.everybody)
        console.log(_fetch.data.everybody)
      }
    } catch (error) {
      console.log(error)
    }
  }

  let LogOut = async () => {
    localStorage.removeItem('buzzUser')
    window.location = '/'
  }

  return (
    <div>
      <nav
        style={{
          height: 80,
          backgroundColor: 'purple',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: `0px 30px`,
        }}
      >
        <img src={Logo} style={{ maxWidth: '50%', maxHeight: '50%' }} />
        <p
          style={{ margin: 0, color: '#fff', cursor: 'pointer' }}
          onClick={LogOut}
        >
          Logout
        </p>
      </nav>
      <section className="container-fluid">
        <div className="row">
          <div className="col-md-8">
            <h3
              className="mb-4 mt-4"
              style={{ fontFamily: `'Quicksand', sans-serif` }}
            >
              Total Users: {users.length}
            </h3>
            <hr />
            <table
              className="table table-hover table-striped"
              style={{ fontFamily: `'Quicksand', sans-serif` }}
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Profile</th>
                  <th>UserName</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, id) => (
                  <tr key={user.userName}>
                    <td>{id + 1}.</td>
                    <td>
                      {user.profileImg ? (
                        <img
                          src={user.profileImg}
                          style={{ height: 30, width: 30, borderRadius: 30 }}
                        />
                      ) : (
                        <div
                          style={{
                            width: 30,
                            height: 30,
                            backgroundColor: '#f6f6f6',
                            borderRadius: 30,
                            border: `0.5px solid purple`,
                          }}
                        ></div>
                      )}
                    </td>
                    <td style={{ textTransform: 'capitalize' }}>
                      {user.userName}
                    </td>
                    <td>{user.authed ? 'active' : 'inactive'}</td>
                    <td>
                      <button
                        className={`btn btn-${
                          user.authed ? 'primary' : 'secondary'
                        }`}
                        onClick={() => takeAction(user.userName, user.authed)}
                      >
                        {user.authed ? 'Deactivate' : 'Activate Account'}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="col-md-4">
            <h3
              className="mb-4 mt-4"
              style={{ fontFamily: `'Quicksand', sans-serif` }}
            >
              Account Resets
            </h3>
            <hr />
            <p style={{ fontFamily: `'Quicksand', sans-serif` }}>
              Click the button below to reset the app.
            </p>
            <p style={{ fontFamily: `'Quicksand', sans-serif` }}>{resetText}</p>
            <button className="btn btn-lg btn-success" onClick={resetApp}>
              Reset App
            </button>
          </div>
        </div>
      </section>
    </div>
  )
}
