import React, { useState } from 'react'
import Logo from '../images/Buzz4.png'
import axios from 'axios'
import { baseUrl } from '../util/http'

export default function Landing() {
  let [userName, setUserName] = useState('')
  let [password, setPassword] = useState('')
  let [loadingText, setLoadingText] = useState('')
  let [clr, setClr] = useState('')

  const Login = async (e) => {
    e.preventDefault()
    setLoadingText('please wait ...')
    setClr('white')
    console.log({ userName, password })
    try {
      let _login = await axios.post(`${baseUrl}/users/admin-login`, {
        email: userName,
        password,
      })

      if (_login.data.message === 'Match Found') {
        setLoadingText('User Found!!!')
        setClr('white')
        window.localStorage.setItem('buzzUser', userName)
        window.location = '/dashboard'
        // console.log(_login.data.message)
      }
    } catch (error) {
      //   console.log(error.response.data)
      setClr('white')
      setLoadingText(error.response.data.message)
    }
  }

  let sendResetLink = async () => {
    setLoadingText('Sending Link To Your Email')
    setClr('white')
    await axios.get(`${baseUrl}/users/resetPassword`)
    setLoadingText('Email sent! Check your spam folder as well.')
  }

  return (
    <div className="wrapper">
      <div className="container-fluid">
        <div className="row justify-content-center align-items-center flex-column">
          <img
            src={Logo}
            style={{ maxWidth: '10%', maxHeight: '10%', marginTop: 150 }}
            alt="logo"
          />
          <div
            className="col-md-4"
            style={{
              height: 350,
              marginTop: 58,
              padding: `50px 50px`,
            }}
          >
            <form onSubmit={Login}>
              <label
                htmlFor=""
                style={{
                  fontFamily: `'Quicksand', sans-serif`,
                  marginBottom: 4,
                  fontSize: 14,
                  color: '#fff',
                }}
              >
                Username :
              </label>
              <input
                type="text"
                className="form-control form-control-lg mb-3 form_input"
                placeholder="Enter Admin Username"
                value={userName}
                onChange={({ target: { value } }) => setUserName(value)}
                style={{ height: 70 }}
                required
              />

              <label
                htmlFor=""
                style={{
                  fontFamily: `'Quicksand', sans-serif`,
                  marginBottom: 4,
                  fontSize: 14,
                  color: '#fff',
                }}
              >
                Password :
              </label>
              <input
                type="password"
                className="form-control form-control-lg mb-3 form_input"
                placeholder="Enter Admin Password"
                value={password}
                onChange={({ target: { value } }) => setPassword(value)}
                style={{ height: 70 }}
                required
              />

              <p
                style={{ fontSize: 12, margin: `0px 0px` }}
                className={`text-${clr}`}
              >
                {loadingText}
              </p>

              {/* <label htmlFor="">Password</label>
              <input
                type="password"
                className="form-control form-control-lg mb-3 form_input"
                placeholder="Enter Password"
                value={password}
                onChange={password}
              /> */}

              <div className="d-flex flex-row justify-content-between align-items-center">
                <button className="btn" style={{ backgroundColor: '#fff' }}>
                  Login
                </button>

                <p
                  style={{ cursor: 'pointer', color: '#fff', marginTop: 20 }}
                  onClick={sendResetLink}
                >
                  Reset Password
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
